<template >
  <div>
    <div class="banner">
      <div v-if="bannerList.length>0" class="shop_banner swiper-container-initialized swiper-container-horizontal">
        <swiper :options="swiperOptions" class="swiper-wrapper">
          <swiper-slide class="swiper-slide" v-for="(item,index) in bannerList[0].image_fir" :key="index">
            <img  :src="item" />
          </swiper-slide> 
        </swiper>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from 'swiper';
export default {
  data() {
    return {
      bannerList:[],
      swiperOptions:{
        loop: true,
          autoHeight: true,
          speed: 1000,
          autoplay: {
            disableOnInteraction: false
          },
          pagination: {
            el: '.banner .swiper-pagination',
            clickable: true,
          }
      }
    }
  },
  created(){
    this.getBanners();
  },

  methods: {
    async getBanners(){
      const res = await this.$request.get('banner/getpaginate')
      if (res.data.code===0) {
        this.bannerList=res.data.data.data
        this.bannerList[0].image_fir=this.bannerList[0].image_fir.split(',')
      }
    },
    
  }
}
</script>
<style lang="">
  
</style>